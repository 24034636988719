import { useActionData, useTransition } from "@remix-run/react";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import { RunningDog } from "../components/running-dog";

const DEFAULTS = {
  messages: {
    isRedirecting: "Redirecting...",
    isLoading: "Loading...",
  },
};

export function useActionSubmissionToast(
  _options: Partial<typeof DEFAULTS> = DEFAULTS
) {
  const options = useMemo(() => ({ ...DEFAULTS, ..._options }), [_options]);
  const [toastId, setToastId] = useState<ReturnType<typeof toast>>("tid");
  const transition = useTransition();
  const isLoading = transition.state !== "idle";
  const isRedirecting = transition.type === "actionRedirect";

  useEffect(() => {
    if (isRedirecting) {
      setToastId(
        toast.success(options.messages.isRedirecting, {
          id: toastId,
          duration: 10000,
          icon: <RunningDog animation="speak" />,
        })
      );
      return;
    }
    if (isLoading) {
      setToastId(
        toast.loading(options.messages.isLoading, {
          id: toastId,
          icon: <RunningDog animation="walking" />,
        })
      );
      return;
    }

    if (!isLoading) {
      toast.dismiss(toastId);
    }
  }, [
    isLoading,
    isRedirecting,
    options.messages.isLoading,
    options.messages.isRedirecting,
    setToastId,
    toastId,
  ]);

  useEffect(
    () => () => {
      // When the component dismounts, wait a moment before dismissing the toast.
      setTimeout(() => {
        toast.dismiss(toastId);
      }, 1500);
    },
    [toastId]
  );

  const result = { toastId, isLoading, isRedirecting, options };
  return result;
}

export function useErrorToast(
  options: ReturnType<typeof useActionSubmissionToast>
) {
  const actionData = useActionData();
  const [toastId] = useState(uuid());
  const msg = actionData?.error?.message || actionData?.error;
  useEffect(() => {
    if (!msg || options.isLoading || options.isRedirecting) return;
    toast.error(msg, { id: options.toastId || toastId });
  }, [msg, toastId, options.toastId, options.isLoading, options.isRedirecting]);
}
